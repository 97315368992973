const SK_navbar = [
    {
        id: 0,
        text: "O nás",
        navRoute: "About_us",
    },
    {
        id: 1,
        text: "Služby",
        navRoute: "Services",
    },
    {
        id: 2,
        text: "Produkty",
        navRoute: "Products",
    },
    {
        id: 3,
        text: "Kontakty",
        navRoute: "Contacts",
    }
]

export default SK_navbar