import "./InfoBox.css"
import React from 'react'

const InfoBox = ({Title, Picture, Text}) => {
  return (
    <div className="infoBoxMain">
        <h3 className="infoTItle">{Title}</h3>
        <img src={Picture} alt="service_info_pic" className="infoPic"/>
        <div className="infoText">{Text}</div>
    </div>
  )
}

export default InfoBox