const aboutUS ={
    comapnyName: "Silver Ant s.r.o.",
    founded: 2020,
    address: {
        street: "Križovany nad Dudváhom 480",
        PSC: "919 24",
        city: "Križovany nad Dudváhom",
        state: "Slovak Republic",
        mail: "info@silverant.sk",
    },
    billingInfo: {
        ICO: "53190190",
        DIC: "2121296056",
        DPH: "SK2121296056",
        IBAN: "SK36 1100 0000 0029 4109 0393",
        SWIFT: "TATRSKBX",
    },
    contacts: [
        {
            name: "Ing. Radovan Demko",
            function: "CEO",
            phone: "+421 911 329 182",
            mail: "radovan.demko@silverant.sk"
        },
        {
            name: "Ing. Marek Duchovič",
            function: "CEO",
            phone: "+421 915 771 637",
            mail: "marek.duchovic@silverant.sk"
        }
    ],
    short_description: "Silver Ant s.r.o. is a Slovak company focused on the development and production of universal testing systems and single-purpose devices for industrial production meeting Industry 4.0 standards.",
    motivation: "WE ARE A SMALL COMPANY POISED FOR GROWTH, BACKED BY AN EXPERIENCED TEAM.",
    description: "Silver Ants.r.o.was established in July 2020 during challenging times in the manufacturing industry when outsourcing became a strategic necessity for productivity and effectiveness. With years of experience working as internal staff responsible for developing and maintaining testing equipment for large multinational companies, we understand the critical importance of swiftly introducing new products to market while ensuring their quality.\tOur expertise led us to establish a company dedicated to sharing this knowledge, potentially benefiting your company. Our primary focus is on providing external support for end-of-line product testing and testing improvements for manufacturing companies, particularly those where time-to-market and internal resource efficiency are paramount.",
    offer: "What we offer:",
    offers: [
        "Our own comprehensive functional testing solutions built on an universal open platform.",
        "Assistance with optimizing your existing testing solutions.",
        "Tailored solutions for businesses of any size, whether small or large.",
    ],
    vision: "We are committed to helping you streamline your testing processes and ensure the quality and efficiency of your products.",
}

export default aboutUS