import "./ContactBox.css"
import React from 'react'

const ContactBox = ({Contact}) => {
  return (
    <div className="contactsBox">
        <p>{Contact.name}</p>
        <p>{Contact.function}</p>
        <p>{Contact.phone}</p>
        <a href={`mailto:${Contact.mail}`}>{Contact.mail}</a>
    </div>
  )
}

export default ContactBox