import "./Banner.css"
import React from 'react'

const Banner = ({Picture, ...props}) => {
  return (
    <div className="bannerBox">
      <img src={Picture} alt="bannerPic" className="picture" {...props} />
    </div>
  )
}

export default Banner