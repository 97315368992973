import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Header from "./layout/Header"
import EndContactBox from "./layout/EndContactBox"
import { LanguageContext } from "./helper/LanguageContext"

import routes from "./data/routes.js"

function App() {
  const [selectedLang, setSelectedLang] = useState('sk')

  return (
    <LanguageContext.Provider value={{selectedLang, setSelectedLang}}>
      <HelmetProvider>
      <div className="App">
        <div className="appBody">
          <BrowserRouter>
            <Header />
            <div className="subAppBody">
              <Routes>
                <Route path="*" element={<Navigate to="/" replace />} />
                {
                  routes.map(route => (
                    <Route key={route.path} path={route.path} exact Component={route.element} >
                      {route.children && route.children.map(childRoute => (
                        <Route key={childRoute.path} path={childRoute.path} exact Component={childRoute.element} />
                      ))}
                    </Route>
                  ))
                }
              </Routes>
              <EndContactBox />
            </div>
          </BrowserRouter>
        </div>      
      </div>
      </HelmetProvider>
    </LanguageContext.Provider>
  );
}

export default App;
