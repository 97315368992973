import "./LanguageSelector.css"
import React, { useContext, useState } from 'react'
import { LanguageContext } from "../../helper/LanguageContext"
import SK_flag from "../../data/img/slovakia.gif"
import US_flag from "../../data/img/us.gif"

const LanguageSelector = (props) => {
    const {selectedLang, setSelectedLang} = useContext(LanguageContext)
    const [isOpen, setIsOpen] = useState(false)

    const handleChange = (code) => { 
      setSelectedLang(code) 
      setIsOpen(false)
    }

    const toggleDropdown = () => setIsOpen(!isOpen)

    const languages = [
      { code: 'en', name: 'English', flag: US_flag},
      { code: 'sk', name: 'Slovak', flag: SK_flag },
    ]

    const selLang = languages.find(lang => lang.code === selectedLang);

  return (
    <div className="language-switcher">
      <button onClick={toggleDropdown} className="dropdown-btn">
        <img src={selLang.flag} alt={selLang.name} className="flag" />
        {selLang.name}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {languages.map(lang => (
            <li key={lang.code} onClick={() => handleChange(lang.code)}>
              <img src={lang.flag} alt={lang.name} className="flag" />
              {lang.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default LanguageSelector