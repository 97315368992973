import "./ContactBox.css"
import React from 'react'

const AddressBox = ({Address}) => {
  return (
    <div className="addressBox">
        <p>{Address.street}</p>
        <p>{Address.PSC}, {Address.city}</p>
        <p>{Address.state}</p>
    </div>
  )
}

export default AddressBox