import "./Products.css"
import "./MainBody.css"
import React, { useContext } from 'react'
import { Outlet, useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { LanguageContext } from "../helper/LanguageContext"
import { Banner } from "../components"

import picBanner from "../data/photo/CU_detail.jpg"

const Products = () => {
  const location = useLocation()
  const isParentRoute = location.pathname === '/Products'
  const {selectedLang} = useContext(LanguageContext)

  return (
    <div className="mainBody">
      <Helmet>
        <title>{selectedLang==="en" ? "Products" : "Produkty"} | SilverAnt s.r.o</title>
        <meta name='description' content='Fotografie našich produktov, FCT testerov, JIGov, smart senzorov, a iných zariadení' />
      </Helmet> 
      <Banner Picture={picBanner} style={{ 'max-height': '200px', objectPosition: '0px 33%' }} />
      {isParentRoute && <div>{selectedLang==="en" ? "We prepare" : "Pripravujeme"}</div>}
      <Outlet />
    </div>
  )
}

export default Products