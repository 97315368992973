import image1 from "../photo/4LED_rack.jpg"
import image2 from "../img/adapter.png"
import image3 from "../img/sourceCode.jpg"
import image4 from "../img/StepLight.jpg"

const EN_services = [
    {
        id: 0,
        navRoute: null,
        title: "Our Services",
        short_description: null,
        image: null,
        description: null,
        properties: []
    },
    {
        id: 1,
        navRoute: "FCT",
        title: "Functional Tester",
        short_description: "Open architecture according to your needs",
        image: image1,
        description: "We specialize in the production of functional testers tailored to the needs of our devices.",
        properties: [
            "Testing electrical parameters",
            "MCU programming",
            "Complete functional test of the device",
            "Testing light parameters",
            "Burn-In test",
        ]
    },
    {
        id: 2,
        navRoute: "CustomDesign",
        title: "Custom Manufacturing",
        short_description: "Manufacture of mechanical or electrical equipment according to your needs",
        image: image2,
        description: "If you need specialized mechanical or electrical equipment for your company, we can design and manufacture it for you.",
        properties: [
            "Simple testing/programming stations",
            "Single-purpose devices (JIGs)",
            "Electrical panels and control electronics for your machines",
        ]
    },
    {
        id: 3,
        navRoute: "SW_dev",
        title: "Software Development",
        short_description: "Programming own test, database and display applications",
        image: image3,
        description: "Various software solutions according to your needs",
        properties: [
            "Data display and database management",
            "Control software for simultaneous operation of multiple devices",
            "Creation of DLLs for handling measuring, power supply, etc. devices",
            "Option for web or desktop GUI",            
        ]
    },
    {
        id: 4,
        navRoute: "Ele_dev",
        title: "Electronics Development",
        short_description: "Design and manufacture of custom electronics and PCBs",
        image: image4,
        description: "",
        properties: [
            "Multiplexer, control, and communication boards for industrial machines",
            "Communication converters",
            "Various modules for larger projects",
            "Development and production of smart sensors and devices for monitoring and controlling households, production, and office spaces...",            
        ]
    },
]

export default EN_services
