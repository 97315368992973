import "./ContactBox.css"
import React from 'react'

const BillingBox = ({Billing_text, Billing_data}) => {
  return (
    <div className="billingBox">
        <p>{Billing_text.ICO}: {Billing_data.ICO}</p>
        <p>{Billing_text.DIC}: {Billing_data.DIC}</p>
        <p>{Billing_text.DPH}: {Billing_data.DPH}</p>
        <p>{Billing_text.IBAN}: {Billing_data.IBAN}</p>
        <p>{Billing_text.SWIFT}: {Billing_data.SWIFT}</p>
    </div>
  )
}

export default BillingBox