import image1 from "../photo/4LED_rack.jpg";
import image2 from "../img/adapter.png";
import image3 from "../img/sourceCode.jpg";
import image4 from "../img/StepLight.jpg";

const SK_services = [
    {
        id: 0,
        navRoute: null,
        title: "Naše služby",
        short_description: null,
        image: null,
        description: null,
        properties: []
    },
    {
        id: 1,
        navRoute: "FCT",
        title: "Funkčný tester",
        short_description: "Otvorená architektúra, modifikovateľná podľa vašich požiadaviek",
        image: image1,
        description: "Špecializujeme sa na výrobu funkčných testerov presne podľa potrieb našich zákazníkov.",
        properties: [
            "Test elektrických parametrov",
            "Programovanie MCU",
            "Kompletný funkčný test zariadenia",
            "Test svetelných parametrov",
            "Zahorovací \"Burn-In\" test",
        ]
    },
    {
        id: 2,
        navRoute: "CustomDesign",
        title: "Zákazková výroba",
        short_description: "Výroba mechanických alebo elektronických zariadení na mieru",
        image: image2,
        description: "Ak potrebujete špecializované mechanické alebo elektrické zariadenie pre vašu spoločnosť, vieme vám ho navrhnúť a následne vyrobiť.",
        properties: [
            "Jednoduché testovacie/programovacie stanice",
            "Jednoúčelové zariadenia (JIG-y)",
            "Elektrické rozvádzače a riadiaca elektronika do vašich strojov",
        ]
    },
    {
        id: 3,
        navRoute: "SW_dev",
        title: "Vývoj softvéru",
        short_description: "Programovanie vlastného testovacieho, databázového, zobrazovacieho SW",
        image: image3,
        description: "Rôzne softvérové riešenia na mieru",
        properties: [
            "Zobrazovanie dát a obsluha databáz",
            "Obslužný softvér pre súčasné ovládanie viacerých zariadení",
            "Tvorba DLL pre obsluhu meracích, napájacích a iných zariadení",
            "Možnosť webového alebo desktopového GUI",            
        ]
    },
    {
        id: 4,
        navRoute: "Ele_dev",
        title: "Vývoj elektroniky",
        short_description: "Návrh a výroba zákazkovej elektroniky DSP",
        image: image4,
        description: "",
        properties: [
            "Multiplexorové, riadiace a komunikačné dosky pre priemyselné stroje",
            "Komunikačné prevodníky",
            "Rôzne moduly pre väčšie projekty",
            "Vývoj a výroba smart senzorov a zariadení pre snímanie a riadenie domácností, výrobných a kancelárskych priestorov",            
        ]
    },
];

export default SK_services;
