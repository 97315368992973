import { AboutUs, Home, Products, Services, Contacts, Service } from '../pages'

const routes = [
    {
        path: '/',
        element: Home,
    },
    {
        path: 'About_us',
        element: AboutUs,
    },
    {
        path: 'Services',
        element: Services,
        children: [
            {
                path: 'FCT',
                element: Service,
            },
            {
                path: 'CustomDesign',
                element: Service,
            },
            {
                path: 'SW_dev',
                element: Service,
            },
            {
                path: 'Ele_dev',
                element: Service,
            },
        ],
    },
    {
        path: 'Products',
        element: Products,
    },
    {
        path: 'Contacts',
        element: Contacts,
    },
]

export default routes