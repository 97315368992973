import "./Contacts.css"
import "./MainBody.css"
import React, { useEffect, useState, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { LanguageContext } from "../helper/LanguageContext"
import { ContactBox, AddressBox, BillingBox, Banner } from "../components"

import EN_aboutUS from "../data/text/EN_aboutUS"
import SK_aboutUS from "../data/text/SK_aboutUS"
import picBanner from "../data/photo/CU_detail.jpg"

const EN_Billing = {
  ICO: "ID number",
  DIC: "TIN",
  DPH: "VAT",
  IBAN: "IBAN",
  SWIFT: "SWIFT",
}

const SK_Billing = {
  ICO: "IČO",
  DIC: "DIČ",
  DPH: "DPH",
  IBAN: "IBAN",
  SWIFT: "SWIFT",
}

const Contacts = () => {
  const {selectedLang} = useContext(LanguageContext)
  const [aboutUsData, setAboutUsData] = useState(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)

  useEffect(() => {
    setAboutUsData(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)
  }, [selectedLang])

  return (
    <div className="mainBody">
      <Helmet>
        <title>{selectedLang==="en" ? "Contacts" : "Kontakty"} | SilverAnt s.r.o</title>
        <meta name='description' content='Fakturačné údaje, kontakty' />
      </Helmet> 
      <Banner Picture={picBanner} style={{ 'max-height': '200px', objectPosition: '0px 33%' }} />
      <div className="midyBody">
        
        <div className="address_billing">
          <h3>{selectedLang==="en" ? "Adress" : "Adresa"}</h3>
          <AddressBox Address={aboutUsData.address} />

          <h3>{selectedLang==="en" ? "Billing Information" : "Fakturačné Informácie"}</h3>
          <BillingBox 
            Billing_text={selectedLang==="en" ? EN_Billing : SK_Billing }
            Billing_data={aboutUsData.billingInfo} />
        </div>

        <div className="contacts">
          <h3>{selectedLang==="en" ? "Contacts" : "Kontakty"}</h3>
          {aboutUsData.contacts.map((contact) => {
            return (<ContactBox Contact={contact} />)
          })}
        </div>

      </div>
    </div>
  )
}

export default Contacts