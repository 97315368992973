import "./Header.css"
import React, { useState, useContext, useEffect } from 'react'
import { LanguageContext } from "../helper/LanguageContext"
import { Link, NavLink } from "react-router-dom"
import Logo from "../data/img/SA_logo.png"

import SK_navbar from "../data/text/SK_navbar"
import EN_navbar from "../data/text/EN_navbar"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const {selectedLang} = useContext(LanguageContext)
  const [navData, setNavData] = useState(selectedLang==="en" ? EN_navbar : SK_navbar)

  useEffect(() => {
    setNavData(selectedLang==="en" ? EN_navbar : SK_navbar)
  }, [selectedLang])


  return (
    <nav className='mainHeader'>
      <Link className="companyLogo" to="/" onClick={() => setMenuOpen(false)}>
        <img src={Logo} alt="Company Logo" className="logoClass" />
      </Link>

      <div className="menuHamburger" onClick={() => setMenuOpen(!menuOpen)}>
        <div className="hamLine"></div>
        <div className="hamLine"></div>
        <div className="hamLine"></div>
        <div className="hamLine"></div>
      </div>
      
      <ul className={menuOpen ? "openMenu" : ""}>
        {navData.map((item) => {
          return (
            <li>
              <NavLink className="navButon" to={item.navRoute} onClick={() => setMenuOpen(false)}>{item.text}</NavLink>
            </li>
          )
        })}
      </ul> 

    </nav>
  )
}

export default Header