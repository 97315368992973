const EN_navbar = [
    {
        id: 0,
        text: "About us",
        navRoute: "About_us",
    },
    {
        id: 1,
        text: "Services",
        navRoute: "Services",
    },
    {
        id: 2,
        text: "Products",
        navRoute: "Products",
    },
    {
        id: 3,
        text: "Contacts",
        navRoute: "Contacts",
    }
]

export default EN_navbar