import "./Services.css"
import "./MainBody.css"
import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from "react-router-dom"
import { LanguageContext } from "../helper/LanguageContext"

import SK_services from "../data/text/SK_services"
import EN_services from "../data/text/EN_services"

const Service = () => {
  const location = useLocation()
  const isParentRoute = location.pathname
  const {selectedLang} = useContext(LanguageContext)
  const [servicesData, setServicesData] = useState(selectedLang==="en" ? EN_services : SK_services)

  useEffect(() => {
    setServicesData(selectedLang==="en" ? EN_services : SK_services)
  }, [selectedLang])

  const service = servicesData.filter((oneService) => {
                    return isParentRoute.includes(oneService.navRoute)
                  })

  return (
    <div className="serviceDIV">
      <Helmet>
        <title>{service[0].title} | SilverAnt s.r.o</title>
        <meta name='description' content={service[0].description} />
      </Helmet> 
      <h3>{service[0].title}</h3>
      <p>{service[0].description}</p>
      <div className="propertiesBox">
        <img src={service[0].image} alt="service_info_pic" className="servicePic"/>
        <ul>        
          {service[0].properties.map((property, id) => {
            return (<li>{property}</li>)
          })}
        </ul>
      </div>
    </div>
  )
}

export default Service