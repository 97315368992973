import "./EndContactBox.css"
import React, { useState, useContext, useEffect } from 'react'
import { LanguageContext } from "../helper/LanguageContext"
import { Link } from "react-router-dom"
import { LanguageSelector } from "../components"
import Logo from "../data/img/SA_logo.png"

import SK_navbar from "../data/text/SK_navbar"
import EN_navbar from "../data/text/EN_navbar"
import EN_aboutUS from "../data/text/EN_aboutUS"
import SK_aboutUS from "../data/text/SK_aboutUS"

const EN_Billing = {
  ICO: "ID number",
  DIC: "TIN",
  DPH: "VAT",
  IBAN: "IBAN",
  SWIFT: "SWIFT",
}

const SK_Billing = {
  ICO: "IČO",
  DIC: "DIČ",
  DPH: "DPH",
  IBAN: "IBAN",
  SWIFT: "SWIFT",
}

const EndContactBox = () => {
  const {selectedLang} = useContext(LanguageContext)
  const [navData, setNavData] = useState(selectedLang==="en" ? EN_navbar : SK_navbar)
  const [aboutUsData, setAboutUsData] = useState(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)

  useEffect(() => {
    setNavData(selectedLang==="en" ? EN_navbar : SK_navbar)
    setAboutUsData(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)
  }, [selectedLang])


  return (
    <div className="endContactBox">
      <div className="endBoxLogo">
          <img src={Logo} alt="Company Logo" className="endLogoClass" />
      </div>

      <div className="endBoxMenu">
        <h4>Menu</h4>
        <ul>
          {navData.map((item) => {
            return (
              <li>
                <Link className="navButtom" to={item.navRoute}>{item.text}</Link>
              </li>
            )
          })}
        </ul> 
      </div>

      <div className="endBoxAddress">
        <h4>{selectedLang==="en" ? "Adress" : "Adresa"}</h4>
        <p>{aboutUsData.address.street}</p>
        <p>{aboutUsData.address.PSC}, {aboutUsData.address.city}</p>
        <p>{aboutUsData.address.state}</p>
        <p>{aboutUsData.address.mail}</p>   
      </div>    

      <div className="endBoxBilling">
        <h4>{selectedLang==="en" ? "Billing Information" : "Fakturačné Informácie"}</h4>
        <p>{(selectedLang==="en" ? EN_Billing : SK_Billing).ICO}: {aboutUsData.billingInfo.ICO}</p>
        <p>{(selectedLang==="en" ? EN_Billing : SK_Billing).DIC}: {aboutUsData.billingInfo.DIC}</p>
        <p>{(selectedLang==="en" ? EN_Billing : SK_Billing).DPH}: {aboutUsData.billingInfo.DPH}</p>
        <p>{(selectedLang==="en" ? EN_Billing : SK_Billing).IBAN}: {aboutUsData.billingInfo.IBAN}</p>
        <p>{(selectedLang==="en" ? EN_Billing : SK_Billing).SWIFT}: {aboutUsData.billingInfo.SWIFT}</p>
      </div>

      <div className="endBoxLang"><LanguageSelector/></div>
    </div>
  )
}

export default EndContactBox