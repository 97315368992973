import "./AboutUs.css"
import "./MainBody.css"
import React, { useEffect, useState, useContext } from 'react'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { LanguageContext } from "../helper/LanguageContext"
import { Banner } from "../components"

import EN_aboutUS from "../data/text/EN_aboutUS"
import SK_aboutUS from "../data/text/SK_aboutUS"
import picBanner from "../data/photo/CU_detail.jpg"
import VSlogo from "../data/img/Vossloh-Schwabe-logo.png"
import AVEXlogo from "../data/img/avex-electronics-logo.png"
import SITECOlogo from "../data/img/siteco_logo.png"


const createParagraphs = (text) => {
  return text.split('\t').map((paragraph, id) => (
    <p key={id}>{paragraph.trim()}</p>
  ));
}

const AboutUs = () => {
  const {selectedLang} = useContext(LanguageContext)
  const [aboutUsData, setAboutUsData] = useState(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)

  useEffect(() => {
    setAboutUsData(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)
  }, [selectedLang])


  return (
    <div className="mainBody">
      <Helmet>
        <title>{selectedLang==="en" ? "About us " : "O nás"} | SilverAnt s.r.o</title>
        <meta name='description' content='Popis našej spoločnosti, prečo sme vznikli a čo ponúkame.' />
      </Helmet> 
      <Banner Picture={picBanner} style={{ 'max-height': '200px', objectPosition: '0px 33%' }} />
      <div className="aboutUsText">
        <p>{aboutUsData.motivation}</p>

        {createParagraphs(aboutUsData.description)}

        <p>{aboutUsData.offer}</p>
        <ul>        
          {aboutUsData.offers.map((offer, id) => {
            return (<li>{offer}</li>)
          })}
        </ul>

        <p>{aboutUsData.vision}</p>
      </div>      

      <div className="ourPartners">
        <h3>{selectedLang==="en" ? "Our partners" : "Naši partnery"}</h3>
        <div className="ourPartnersLinks">
          <Link className="ourPartnersLink" to="https://www.vossloh-schwabe.com/en">
            <img src={VSlogo} alt="VS_logo" className="partnersLogos" />
          </Link>
          <Link className="ourPartnersLink" to="https://www.avex.sk">
            <img src={AVEXlogo} alt="AVEX_logo" className="partnersLogos" />
          </Link>
          <Link className="ourPartnersLink" to="https://www.siteco.com/">
            <img src={SITECOlogo} alt="Siteco_logo" className="partnersLogos" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AboutUs