const aboutUS = {
    companyName: "Silver Ant s.r.o.",
    founded: 2020,
    address: {
        street: "Križovany nad Dudváhom 480",
        PSC: "919 24",
        city: "Križovany nad Dudváhom",
        state: "Slovenská republika",
        mail: "info@silverant.sk",
    },
    billingInfo:{
        ICO: "53190190",
        DIC: "2121296056",
        DPH: "SK2121296056",
        IBAN: "SK36 1100 0000 0029 4109 0393",
        SWIFT: "TATRSKBX",
    },
    contacts:[
        {
            name: "Ing. Radovan Demko",
            function: "konatel",
            phone: "+421 911 329 182",
            mail: "radovan.demko@silverant.sk"
        },
        {
            name: "Ing. Marek Duchovič",
            function: "konatel",
            phone: "+421 915 771 637",
            mail: "marek.duchovic@silverant.sk"
        }
    ],
    short_description: "Silver Ant s.r.o. je slovenská spoločnosť zameraná na vývoj a výrobu univerzálnych testovacích systémov a jednoúčelových zariadení pre priemyselnú výrobu spĺňajúcich Industry 4.0.",
    motivation: "SME MALÁ SPOLOČNOSŤ PRIPRAVENÁ NA RAST, PODPOROVANÁ SKÚSENÝM TÍMOM.",
    description: "Spoločnosť Silver Ant s.r.o. bola založená v júli 2020 počas náročných čias v výrobnom priemysle, keď sa outsourcing stal strategickou nevyhnutnosťou pre produktivitu a efektívnosť. Vďaka rokom skúseností ako interní zamestnanci zodpovední za vývoj a údržbu testovacieho vybavenia pre veľké nadnárodné spoločnosti chápeme kľúčový význam rýchleho zavádzania nových produktov na trh pri zachovaní ich kvality.\tNaša odbornosť nás viedla k založeniu spoločnosti, ktorá sa venuje zdieľaniu týchto znalostí, čo môže potenciálne prospieť vašej spoločnosti. Naším hlavným zameraním je poskytovanie externej podpory pre koncové testovanie produktov a zlepšovanie testovania pre výrobné spoločnosti, najmä tie, kde je čas na trh a efektívnosť interných zdrojov prvoradé.",
    offer: "Čo ponúkame:",
    offers: [
        "Naše vlastné komplexné funkčné testovacie riešenia postavené na univerzálnej otvorenej platforme.",
        "Pomoc pri optimalizácii vašich existujúcich testovacích riešení.",
        "Riešenia na mieru pre firmy akejkoľvek veľkosti, či už malé alebo veľké."
    ],
    vision: "Zaviazali sme sa pomáhať vám zjednodušiť vaše testovacie procesy a zabezpečiť kvalitu a efektívnosť vašich produktov."
};

export default aboutUS;
