import "./Services.css"
import "./MainBody.css"
import React, { useState, useContext, useEffect } from 'react'
import { Outlet, useLocation, NavLink } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { LanguageContext } from "../helper/LanguageContext"
import { Banner, InfoBox } from "../components"

import picBanner from "../data/photo/CU_detail.jpg"
import SK_services from "../data/text/SK_services"
import EN_services from "../data/text/EN_services"


const Services = () => {
  const location = useLocation()
  const isParentRoute = location.pathname === '/Services'
  const {selectedLang} = useContext(LanguageContext)
  const [servicesData, setServicesData] = useState(selectedLang==="en" ? EN_services : SK_services)

  useEffect(() => {
    setServicesData(selectedLang==="en" ? EN_services : SK_services)
  }, [selectedLang])

  return (
    <div className="mainBody"> 
      <Helmet>
        <title>{selectedLang==="en" ? "Services" : "Služby"} | SilverAnt s.r.o</title>
        <meta name='description' content='Ponúkané služby: Funkčný tester, Zakázková výroba, Vývoj softvéru, Vývoj elektroniky' />
      </Helmet> 
      <Banner Picture={picBanner} style={{ 'max-height': '200px', objectPosition: '0px 33%' }} />
      <div className="servicesBox">
        <h2>{servicesData[0].title}</h2>
        
        {isParentRoute ? <div className="activityBox">
                            {servicesData.map((item) => {
                            if (item.id > 0)
                              return (<NavLink className="navInfoBox" to={item.navRoute}>
                                        <InfoBox key={item.id} Title={item.title} Text={item.short_description} Picture={item.image} />
                                      </NavLink>)
                              })}
                          </div> :
                          <nav className='servicesHeader'>
                            <ul>
                              {servicesData.map((item) => {
                                if (item.id > 0)
                                  return (
                                    <li>
                                      <NavLink className="navButon" to={item.navRoute}>{item.title}</NavLink>
                                    </li>
                                  )
                                })}
                            </ul>
                          </nav>}
        <Outlet />
      </div>
    </div>
  )
}

export default Services