import "./Home.css"
import "./MainBody.css"
import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { LanguageContext } from "../helper/LanguageContext"
import { Banner, InfoBox } from "../components"


import SK_services from "../data/text/SK_services"
import EN_services from "../data/text/EN_services"
import EN_aboutUS from "../data/text/EN_aboutUS"
import SK_aboutUS from "../data/text/SK_aboutUS"
import picBanner from "../data/photo/CU_detail.jpg"

const Home = () => {
  const {selectedLang} = useContext(LanguageContext)
  const [servicesData, setServicesData] = useState(selectedLang==="en" ? EN_services : SK_services)
  const [aboutUsData, setAboutUsData] = useState(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)


  useEffect(() => {
    setServicesData(selectedLang==="en" ? EN_services : SK_services)
    setAboutUsData(selectedLang==="en" ? EN_aboutUS : SK_aboutUS)
  }, [selectedLang])

  return (
    <div className="mainBody">    
      <Helmet>
        <title>SilverAnt s.r.o | {selectedLang==="en" ? "FCT testers" : "Funkčné testery"}</title>
        <meta name='description' content='Spoločnosť zaoberajúca sa vývojom a výrobou testovacích zariadení v súlade s Industry 4.0' />
      </Helmet> 
      <Banner Picture={picBanner} style={{'min-height': '250px',  'max-height': '400px'}} />
      <div className="short_description_box">
        <p>{aboutUsData.short_description}</p>
      </div>
      <div className="activityBox">
        {servicesData.map((item) => {
          if (item.id > 0)
            return (<NavLink className="navInfoBox" to={"Services/" + item.navRoute}>
                      <InfoBox key={item.id} Title={item.title} Text={item.short_description} Picture={item.image} />
                    </NavLink>)
        })}
      </div>
      
    </div>
  )
}

export default Home